import React from "react";
import Header from "../components/Header";

const TourSingle = () => {
  return (
    <>
      <div className="wrapper__toursingle">
        <Header />
        <div className="toursingle__container">
           
        </div>
      </div>
    </>
  );
};

export default TourSingle;
